// 等级管理
import { Request } from '@/http/request'

// 等级列表
export function levelList (parameter: any) {
  return Request.axiosInstance.post('/web/level/list', parameter)
}
// 添加等级 
export function addLevel (parameter: any) {
    return Request.axiosInstance.post('/web/level/add', parameter)
}
// 修改等级 
export function editLevel (parameter: any) {
    return Request.axiosInstance.post('/web/level/edit', parameter)
}
// 删除等级 
export function deleteLevel (parameter: any) {
    return Request.axiosInstance.post('/web/level/delete', parameter)
}

export default { levelList, addLevel, editLevel, deleteLevel }